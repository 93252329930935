import React from 'react';

import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { InitialiserProps, WidgetWrapper } from '../../../types/InitialiserProps';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import MultiModelReviewContracts from '../Contracts/MultiModelReviewContracts';
import MultiModelReviewRetail from '../Retail/MultiModelReviewRetail';
import MultiModelReviewSubscriptions from '../Subscriptions/MultiModelReviewSubscriptions';

import styles from './styles/multi-model-review.css';

loadCSS('multimodelreview');

interface MultiModelReviewProps {
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getWidgetResponse: WidgetProps['getWidgetResponse'];
  postForWidgetResponse: WidgetProps['postForWidgetResponse'];
  getAiredaleFeeds: WidgetProps['getAiredaleFeeds'];
  getModelSuggestions: WidgetProps['getModelSuggestions'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const MultiModelReview: React.FC<MultiModelReviewProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced },
  } = props;
  return (
    <HawkWidgetAdvanced {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { activeTab } = value;

          return (
            <div className={styles.container}>
              {((): JSX.Element => {
                switch (activeTab?.category) {
                  case 'contracts':
                    return <MultiModelReviewContracts />;
                  case 'subscriptions':
                    return <MultiModelReviewSubscriptions />;
                  default:
                    return <MultiModelReviewRetail />;
                }
              })()}
            </div>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

MultiModelReview.getInitialiserProps = async ({
  renderProps: { el, site, articleUrl, ...restRenderProps },
  attributes,
  getWidgetResponse,
  getSeasonalResponse,
  getAiredaleFeeds,
  getModelSuggestions,
  postForWidgetResponse,
  editorial,
  defaultParams,
  url,
  origin,
  dataLinkMerchant,
}): InitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    url,
    origin,
    dataLinkMerchant,
    site,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    placeholder: el,
    articleUrl,
    attributes,
    ...restRenderProps,
  });

  return { props, type, widgetWrapper: WidgetWrapper.DIV };
};

export default MultiModelReview;
